
<template>
  <div class="container">
    <!-- 按钮列表 -->
    <div class="btnList1" ref="btnList">
      <el-button v-if="hasPermiss('bjgl_feiXianFenLei:add')" class="itemBtn btnColor" @click="handleCommand_add()"
        >新建</el-button
      >
      <el-button v-if="hasPermiss('bjgl_feiXianFenLei:update')" class="itemBtn btnColor" @click="updateCategory()"
        >修改</el-button
      >
    </div>
    <!-- 表格 -->
    <div class="tableBox" ref="tableWrapper">
      <el-table
        :height="tableHeigth"
        ref="mytable"
        :data="tableData"
        :row-class-name="tableRowClassName"
        highlight-current-row
        border
        :header-cell-style="{ background: '#f0f0f0' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column fixed="left" label="序号" type="index" width="60">
        </el-table-column>
        <el-table-column fixed="left" type="selection" width="60">
        </el-table-column>
        <template v-for="(item, index) in myTableHeard">
          <el-table-column
            :key="index"
            :label="item.name"
            sortable
            :prop="item.field"
            :width="item.width"
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          >
          </el-table-column>
        </template>
      </el-table>
    </div>

    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="size"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></paging>

    <!-- 新建费项分类 -->
    <el-dialog :title="subTitle" :visible.sync="show_cate">
      <div class="formbox">
        <el-form
          ref="formCate"
          label-position="left"
          :model="infosForm"
          :rules="infoRules"
          label-width="100px"
        >
          <el-form-item label="分类名称" prop="categoryName">
            <el-input v-model="infosForm.categoryName"></el-input>
          </el-form-item>
          <el-form-item label="服务类型" prop="serviceStatus">
            <el-select
              class="inputframe"
              v-model="infosForm.serviceStatus"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in cateStatusArr"
                :key="item.statusVal"
                :label="item.showName"
                :value="item.statusVal"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="headebut">
        <el-button @click="submitInfo('formCate')" type="warning"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import paging from "@/components/pagings.vue";
export default {
  inject: ["reload"],
  components: {
    paging,
  },
  data() {
    return {
      // ================
      pageNum: 1, //页码
      total: 0, //总条数
      sizeList: [10, 20, 50, 100, 200],
      size: 50, //一页几条
      nextPage: false, //是否有下一页
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
     myTableHeard: [], //我的表头
originalTabelHeadeTitle: [],
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      tableHeigth: "50vh",
      tabelHeadeTitle: [
        {
          name: "分类名称",
          field: "categoryName",
          sort: 1,
          isShow: true,
          isTotal: false,
        },
        {
          name: "服务类型",
          field: "serviceStatusShow",
          sort: 2,
          isShow: true,
          isTotal: false,
        },
      ],
      tableData: [],
      totalArr: [], //需要合计的字段
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: "", //上传路径
      sortName: "模板名", //排序名
      pageGroupName: "feixiangfenlei", //页面标识   (注意这里,每个列表定义一个唯一的标识,自定义,设置表头或者下载需要用到)
      show_cate: false,
      infosForm: {
        categoryName: "",
        serviceStatus: "",
        sign: "",
      },
      cateStatusArr: [],
      infoRules: {
        categoryName: [
          { required: true, message: "请输入费项分类名称", trigger: "blur" },
        ],
        serviceStatus: [
          { required: true, message: "请选择服务类型", trigger: "blur" },
        ],
      },
      currentChooseItems: [],
      subTitle: "新建费项分类",
    };
  },
  created() {
    this.uploadUrl = this.common.uploadUrl;
    this.myTableHeard = this.tabelHeadeTitle;
    this.getCommonStatus();
  },
  mounted() {
    const that = this;
    this.getData();
    

 
  },
  watch: {},
  methods: {
    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
    },
    //新建
    handleCommand_add() {
      this.show_cate = true;
      this.subTitle = "新建费项分类";
      if (this.$refs.formCate) {
        this.$refs.formCate.resetFields();
      }
    },
    getCommonStatus() {
      //服务类型
      Api.getStatusValList({
        tableAndFieldName: "item_category_v1.service_status",
      }).then((res) => {
        if (res.data.status == "success") {
          this.cateStatusArr = res.data.result || [];
        }
      });
    },
    //修改
    updateCategory() {
      if (this.currentChooseItems.length == 1) {
        this.show_cate = true;
        this.subTitle = "修改费项分类";
        const curItem = this.currentChooseItems[0];
        this.infosForm = {
          categoryName: curItem.categoryName,
          serviceStatus: curItem.serviceStatus,
        };
      } else {
        this.$message.warning("请选择一个需要修改的费项分类");
      }
    },
    /**
     * =========================按钮部分的方法=>开始??????????????????????????????????
     */
    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },

    /**
     * =========================按钮部分的方法=>结束??????????????????????????????????
     */

    // ===============================================导出部分=开始======
    // 上传导出模板
    uploadSectionFile(param) {
      //console.log(param);
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field);
            heads.push(this.myTableHeard[i].name);
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this;
      let datas = that.tableData;
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);
      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true;
    },
    // 点击导出按钮
    exportBtn() {
      let that = this;
      let fieldName = [];
      let head = [];
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field);
          head.push(this.myTableHeard[i].name);
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          //没有自定义模板,就是下载一个
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            "其他支出列表"
          );
        }
      });
    },
    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        // //console.log(this.tableHeigth + "表格高度");
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;
      let queryHeigth = 0;
      // if (this.judge == true) {
      //   queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
      // } else {
      //   queryHeigth = 50;
      // }
      let btnListHeight = this.$refs.btnList.offsetHeight; // 按钮列表
      let pagsHeigth = 40; //分页组件
      let heardHeight = 60; //头部(黑色部分)
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + heardHeight;
      return Math.floor(windonHeight - otherHeight - 10) || "50vh"; //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 刷新页面==>
    reload_s() {
      this.reload(); //App.Vue里面的方法
    },
    // 获取数据
    getData() {
      let formData = {};
      let that = this;
      formData.pageStart = that.pageNum;
      formData.pageTotal = that.size;

      Api.getItemCategoryList(formData).then((res) => {
        if (res.data.status == "success") {
          that.className = res.data.result.className || "";
          that.tableData = res.data.result.data || [];
          that.total = res.data.result.pageCount || 0;
          this.fetTableHeight();
        }
      });
    },

    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 表格选择事件
    handleSelectionChange(e) {
      if (e.length > 0) {
        this.currentChooseItems = e;
      } else {
        this.currentChooseItems = [];
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
    submitInfo(formName) {
      let self = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let signParam = {
            categoryName: self.infosForm.categoryName,
            serviceStatus: self.infosForm.serviceStatus,
          };
          if (this.subTitle == "新建费项分类") {
            let sign = tools.getSign(signParam); //调用getSign方法获取签名,该方法在tool.js里面
            signParam.sign = sign;
            Api.addItemCategory(signParam).then((res) => {
              if (res.data.status == "success") {
                self.show_cate = false;
                self.reload();
              }
            });
          } else {
            signParam.id = this.currentChooseItems[0]["itemCategoryId"];
            let sign = tools.getSign(signParam);
            signParam.sign = sign;
            Api.modifyItemCategory(signParam).then((res) => {
              if (res.data.status == "success") {
                self.show_cate = false;
                self.reload();
              }
            });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}
.colorStyle {
  color: #f00;
}
.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}
.formbox ::v-deep {
  width: 60%;
  margin: 0 auto;
}
.headebut {
  width: 60%;
  margin: 0 auto;
  padding-left: 100px;
  box-sizing: border-box;
}
</style>
